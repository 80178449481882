<template>
    <div class="page">
        <div v-if="result">
            <div class="authStatus" v-if="authSuccess">
                <div class="success"></div>
                <p v-if="isMobile">授权成功</p>
                <p v-else>{{result.transportName}}授权成功</p>
                <div class="tips">
                    <p>{{result.tip}}</p>
                </div>
            </div>
            <div class="authStatus" v-else>
                <div class="fail"></div>
                <p v-if="isMobile">授权失败</p>
                <p v-else>{{result.transportName}}授权失败</p>
                <div class="tips">
                    <p>失败原因：达达系统不支持多店模式授权</p>
                    <p>操作建议：请使用达达app商家账号进行授权</p>
                </div>
            </div>
            <div class="btns" v-if="isMobile">
                <div class="btn" v-if="authSuccess" @click="handleClickNativePath('lpmerchant://route/store')">门店管理</div>
                <div class="btn" v-else @click="handleClickNativePath('lpmerchant://route/capacity/self')">重新授权</div>
                <div class="backHome" @click="handleClickNativePath('lpmerchant://route/pop/root')">返回首页</div>
            </div>
        </div>
        <div v-else class="loading">{{loadingMsg}}</div>
    </div>
</template>
<script>
import http from '@/http';

export default {
    data(){
        return {
            result: null,
            authSuccess: false,
            isMobile: false,
            loadingMsg: 'Loading……',
        };
    },
    created() {
        this.postAuthData();
        this.isMobile = this.check();
        
    },
    mounted() {
        
    },
    methods: {
        check(){
            let userAgent = window.navigator.userAgent.toLocaleLowerCase();
            let agents = ['iphone', 'android', 'ipad', 'ipod', 'windows phone', 'symbianos'];
            let flag = false;
            for(let i=0; i<agents.length; i++){
                if(userAgent.indexOf(agents[i]) > 0){
                    flag = true;
                    break;
                }
            };
            this.isMobile = flag;
            return flag;
        },
        postAuthData(){
            http.post('/?cmd=com.xqxc.api.pigear.biz.account.ShopInfoService.addDadaMShopInfo&group=earH5', {
                shopId: this.$route.query.state,
                ticket: this.$route.query.ticket,
                state:this.$route.query.state,
                sourceId: this.$route.query.sourceId,
                shopNo:this.$route.query.shopNo,
            }).then(res => {
                this.result = res.result;
                this.authSuccess = res.result.status === 'PASS';
                if(!this.isMobile){
                    document.title == res.result.transportName + '授权';
                }
            }).catch(err=>{
                this.loadingMsg = 'Loading fail';
            });
        },
        handleClickNativePath(path) {
            window.location.href = path
        }
    },
}
</script>
<style lang="less" scoped>
.loading{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .3rem;
}
.page { 
    padding: .3rem;
    font-size: .28rem;
    line-height: 1.5;

    .btn{
        width: 6.9rem;
        height: .88rem;
        border-radius: .1rem;
        background: #00ABEB;
        color: #FFF;
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .2rem auto;
    }
    .backHome{
        width: 6.9rem;
        height: .88rem;
        border-radius: .1rem;
        background: #2dc7ff;
        color: #FFF;
        font-size: .32rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: .2rem auto;
    }
}
.btns{
    position: fixed;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.authStatus{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .success, .fail{
        width: 2rem;
        height: 2rem;
        overflow: hidden;
        border-radius: 50%;
        margin-top: 1rem;
    }
    .success{
        background: url('../../assets/images/icon-success.png') no-repeat center;
        background-size: cover;
    }
    .fail{
        background: url('../../assets/images/icon-fail.png') no-repeat center;
        background-size: cover;
    }
    p{
        margin-top: 10px;
        font-size: .34rem;
        text-align: center;
    }
    .tips{
        padding: .3rem;
        p{
            font-size: .28rem;
            line-height: .34rem;
            text-align: left;
            color: #666;
        }
    }
}
    
</style>